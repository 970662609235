<template>
  <el-dialog
      :visible.sync="PapersPreviewdialogVisible"
      title="试卷预览"
      top="1%"
      width="60%"
      :center="true"
      :modal="false"
      :before-close="PapersPreviewHandleClose"
  >
    <div>
      <el-row style="margin-bottom: 15px">
        <h3 style="margin-bottom: 15px">试卷列表</h3>
        <div class="searchbox">
          <div title="试卷名称" class="searchboxItem"  style="line-height: 32px;margin-bottom: 16px;vertical-align: top">
            <span class="itemLabel" style="width: 5em;min-width: 5em;">试卷名称:</span>
            {{ paperName }}
          </div>
          <div title="创建时间" class="searchboxItem" >
            <span class="itemLabel" style="width: 5em;line-height: 32px;">创建时间:</span>
            <el-date-picker
                style="width: 280px!important;"
                v-model="searchData.createTime"
                type="daterange"
                size="small"
                range-separator="至"
                value-format="yyyy-MM-dd"
                value="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            ></el-date-picker>
            <el-button size="small" type="primary" class="bgc-bv" round @click="getData()" style="margin-left: 16px;"
            >查询</el-button
            >
          </div>
        </div>
      </el-row>
      <el-row>
        <el-table
            ref="tableData"
            :data="tableData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
        >
          <el-table-column
              label="序号"
              align="center"
              type="index"

              :index="indexMethod"
              min-width="100"
          />
          <el-table-column
              label="试卷编号"
              align="left"
              show-overflow-tooltip
              min-width="140"
          >
            <template slot-scope="{ row }">
              {{ row.paperName }} - {{ row.paperNum }}
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              align="left"
              prop="createTime"
              show-overflow-tooltip
              min-width="140"
          />
          <el-table-column
              label="操作"
              align="center"
              width="180"
          >
            <div slot-scope="{ row }" class="flexcc">
              <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="handleDialogPapersPreview(row)"
              >预览</el-button
              >
              <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="handleDialogDelete(row)"
              >删除</el-button
              >
              <el-button
                  type="text"
                  style="padding:0px 5px"
                  size="mini"
                  @click="handleDialogDownload(row)"
              >下载</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </el-row>
      <PageNum
          style="margin-top: 15px"
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
      />
    </div>

    <!--试卷预览列表-预览-->
    <PapersShow ref="PapersShow" :modal="false" />
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

import PapersShow from "@/views/appraisalInstitution/AppraisalQuestionBank/popup/PapersShow";
import { mapGetters } from "vuex";

export default {
  name: "PapersPreview",
  components: {
    Empty,
    PageNum,
    PapersShow,
  },
  mixins: [List],
  data() {
    return {
      // 试卷预览
      PapersPreviewdialogVisible: false,
      questionBankId: '',
      currentPageNum: 0,
      paperName: '',

      searchData: {
        createTime: ''
      },
    };
  },

  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {

    async getData(pageNum = 1) {
      if(this.questionBankId){
        const params = {
          pageNum: pageNum,
          pageSize: this.pageSize,
          questionBankId: this.questionBankId
        };
        this.currentPageNum = pageNum

        if (this.searchData.createTime) {
          params.startDate = this.searchData.createTime[0];
          params.endDate = this.searchData.createTime[1];
        }
        return this.doFetch({
          url: "/biz/hr_examination/config/selectPaperList",
          params,
          pageNum,
        },true,6);
      }

    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    async showPopUp(questionBankId) {
      this.questionBankId = questionBankId;

      await this.getData();

      this.PapersPreviewdialogVisible = true


      this.getPaperName()
    },
    getPaperName(){
      setTimeout(()=>{
        if(this.tableData&&this.tableData.length>0&&this.tableData[0].paperName){
          this.paperName = this.tableData[0].paperName
        }else{
          if(this.paperName != '--'){
            this.paperName = '--'
            this.getPaperName()
          }

        }

      },300)
    },

    // 试卷预览关闭
    PapersPreviewHandleClose(){
      this.searchData.createTime = ''
      this.PapersPreviewdialogVisible = false
    },
    // 试卷预览预览
    handleDialogPapersPreview(row){

      this.$refs.PapersShow.showPopUp(row.paperId,row); // 打开弹窗预览试题
    },
    /* 试卷预览删除 */
    handleDialogDelete(row) {

      if (row.downloadState) {
        this.$confirm("已经下载此试卷，删除后将查询不到试卷内容，确认删除?", "删除", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning"
        })
            .then(() => {
              this.$post("/biz/hr_examination/config/deletePaper", { paperId: row.paperId },3000,true,6).then(res => {
                if (res.status == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });
                  if(this.currentPageNum == 1&&this.tableData.length == 1){
                    this.PapersPreviewdialogVisible = false
                  }else{
                    this.getData(-1)
                  }
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除"
              });
            });

      } else {
        this.$confirm("您确认删除该试卷吗?", "删除", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning"
        })
            .then(() => {
              this.$post("/biz/hr_examination/config/deletePaper", { paperId: row.paperId },3000,true,6).then(res => {
                if (res.status == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });

                  if(this.currentPageNum == 1&&this.tableData.length == 1){
                    this.PapersPreviewdialogVisible = false
                  }else{
                    this.getData(-1)
                  }
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除"
              });
            });
      }
    },
    // 试卷预览下载
    handleDialogDownload(row){
      console.log(row)
      this.$post("/biz/hr_examination/config/asyncDownloadPaper", {paperId: row.paperId},3000,true,6)
          .then((res) => {
            if (res.status == "0") {
              console.log(res.data)
              for (let item of res.data) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });
    },
  },
};
</script>
<style lang="less" scoped>

.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
/deep/.el-table__body-wrapper{
  min-height: 360px;
}
.searchbox > div{
  align-items: start;
}
.searchboxItem{
  margin-right: 20px;
}
/deep/.el-range-editor .el-range-input{
  position: relative;
  top: -2px;
}
</style>

<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">题库试卷管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="questionbankName"
                type="text"
                size="small"
                placeholder="请输入题库名称"
                clearable
              />
            </div>
<!--            <span title="题库类别" class="searchboxItem ci-full flexcc">-->
<!--              <span class="itemLabel">题库类别:</span>-->
<!--              <el-cascader-->
<!--                v-model="categoryCode"-->
<!--                :options="TreeList"-->
<!--                :props="propsTree"-->
<!--                clearable-->
<!--                filterable-->
<!--                size="small"-->
<!--              ></el-cascader>-->
<!--            </span>-->
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                min-width="100"
              />
              <el-table-column
                label="题库名称"
                align="left"
                prop="questionBankName"
                show-overflow-tooltip
                min-width="140"
              />

              <el-table-column
                label="试题数量"
                align="center"
                prop="questionBankNum"
                show-overflow-tooltip
                max-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.questionBankNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                  label="创建时间"
                  align="left"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="140"
              />
              <el-table-column
                label="操作"
                align="center"
                width="180"
              >
                <div slot-scope="{ row }" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handlePaperRules(row)"
                      :disabled="!row.questionBankNum || row.questionBankNum == 0"
                  >试卷规则</el-button
                  >
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handlePapersPreview(row)"
                      :disabled="!row.questionBankNum || row.questionBankNum == 0"
                  >试卷预览</el-button
                  >
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handlePaperDownload(row)"
                      :disabled="!row.questionBankNum || row.questionBankNum == 0"
                  >下载</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!--试卷规则-->
    <el-dialog
        title="试卷规则"
        :visible.sync="paperRulesdialogVisible"
        width="700px"
        :before-close="paperRulesHandleClose"
        class="paperRules"
        :close-on-click-modal="false">
      <div>
        <el-row>
          <span>试卷名称：</span><el-input v-model="paperRulesForm.paperName" size="small" maxlength="50" show-word-limit style="width: 500px;"></el-input>
        </el-row>
        <el-row v-for="(item,index) in paperRulesForm.questionConfigList" :key="index" v-if="item.maxNum>0">
          <span>{{ $setDictionary("QUESTIONTYPE", item.questionType) }}：共{{ item.maxNum }}道题，抽取&nbsp;&nbsp;</span>
          <el-input-number v-model="paperRulesForm.questionConfigList[index].questionNum" size="small" :controls="false" :min="1" :max="item.maxNum" :step="1" :precision="0"></el-input-number>
          <span>&nbsp;&nbsp;道，每题&nbsp;&nbsp;</span>
          <el-input-number v-model="paperRulesForm.questionConfigList[index].questionScore" size="small" :controls="false" :min="0.5" :step="0.5" :precision="2"></el-input-number>
          <span>&nbsp;&nbsp;分，共{{ item.questionNum && item.questionScore?(item.questionNum*item.questionScore).toFixed(2):'0'}}分</span>
        </el-row>
        <el-row>
          <span>共&nbsp;{{ totalQuestion }}&nbsp;道题&nbsp;&nbsp;合计&nbsp;{{totalScore}}&nbsp;分</span>
        </el-row>
        <el-row>
          <span>及格分数&nbsp;&nbsp;</span>
          <el-input-number v-model="paperRulesForm.passScore" size="small" :controls="false" :min="0.5" :max="totalScore" :step="0.5" :precision="2"></el-input-number>
          <span>&nbsp;&nbsp;分</span>
        </el-row>
        <el-row>
          <span>考试时间&nbsp;&nbsp;</span>
          <el-input-number v-model="paperRulesForm.timeLimit" size="small" :controls="false" :min="1" :step="1" :precision="0"></el-input-number>
          <span>&nbsp;&nbsp;分钟</span>
        </el-row>
        <el-row>
          <span>试卷数量&nbsp;&nbsp;</span>
          <el-input-number v-model="paperRulesForm.paperNum" size="small" :controls="false" :min="1" :max="100" :step="1" :precision="0"></el-input-number>
          <span>&nbsp;&nbsp;套</span>
        </el-row>
        <el-row>
          <el-checkbox v-model="paperRulesForm.override">清除原有试卷</el-checkbox>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paperRulesHandleClose">取 消</el-button>
        <el-button type="primary" @click="paperRulesSave">生成试卷</el-button>
      </span>
    </el-dialog>

<!--    &lt;!&ndash;试卷预览&ndash;&gt;-->
<!--    <el-dialog-->
<!--        title="试卷预览"-->
<!--        :visible.sync="PapersPreviewdialogVisible"-->
<!--        width="60%"-->
<!--        :before-close="PapersPreviewHandleClose"-->
<!--        class="paperRules"-->
<!--        :close-on-click-modal="false">-->
<!--      <div>-->
<!--        <el-row>-->
<!--          <h3 style="margin-bottom: 15px">试卷列表</h3>-->
<!--          <span>试卷名称：{{ paperName }}</span>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-table-->
<!--              ref="tableData2"-->
<!--              :data="tableData2"-->
<!--              size="small"-->
<!--              tooltip-effect="dark"-->
<!--              style="width: 100%"-->
<!--              :header-cell-style="tableHeader"-->
<!--              stripe-->
<!--          >-->
<!--            <el-table-column-->
<!--                label="序号"-->
<!--                align="center"-->
<!--                type="index"-->
<!--                min-width="100"-->
<!--            />-->
<!--            <el-table-column-->
<!--                label="试卷编号"-->
<!--                align="left"-->
<!--                show-overflow-tooltip-->
<!--                min-width="140"-->
<!--            >-->
<!--              <template slot-scope="{ row }">-->
<!--                {{ row.paperName }} - {{ row.paperNum }}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                label="创建时间"-->
<!--                align="left"-->
<!--                prop="createTime"-->
<!--                show-overflow-tooltip-->
<!--                min-width="140"-->
<!--            />-->
<!--            <el-table-column-->
<!--                label="操作"-->
<!--                align="center"-->
<!--                width="180"-->
<!--            >-->
<!--              <div slot-scope="{ row }" class="flexcc">-->
<!--                <el-button-->
<!--                    type="text"-->
<!--                    style="padding:0px 5px"-->
<!--                    size="mini"-->
<!--                    @click="handleDialogPapersPreview(row)"-->
<!--                >预览</el-button-->
<!--                >-->
<!--                <el-button-->
<!--                    type="text"-->
<!--                    style="padding:0px 5px"-->
<!--                    size="mini"-->
<!--                    @click="handleDialogDelete(row)"-->
<!--                >删除</el-button-->
<!--                >-->
<!--                <el-button-->
<!--                    type="text"-->
<!--                    style="padding:0px 5px"-->
<!--                    size="mini"-->
<!--                    @click="handleDialogDownload(row)"-->
<!--                >下载</el-button-->
<!--                >-->
<!--              </div>-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--        </el-row>-->
<!--      </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="PapersPreviewHandleClose">取 消</el-button>-->
<!--        <el-button type="primary" @click="PapersPreviewSave">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
    <!--试卷预览列表-->
    <PapersPreview ref="PapersPreview" :modal="false" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import PapersPreview from "@/views/appraisalInstitution/AppraisalQuestionBank/popup/PapersPreview";

import { resetKeepAlive } from "@/utils/common";
import {mapGetters} from "vuex";
export default {
  name: "questionbankList",
  components: {
    PapersPreview,
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      questionbankName: "", //题库名称

      // 试卷规则
      createStu: false,// 生成状态
      questionBankId: '',
      paperRulesdialogVisible: false,
      paperRulesForm: {
        questionConfigList: [],
        passScore: undefined,
        timeLimit: 120,
        paperNum: 1,
        override: false
      },

      // 试卷预览
      // PapersPreviewdialogVisible: false,
      // paperName: '',
      // tableData2: [],
      changePassScore: true //是否需要计算及格分数

    };
  },
  created() {
    // this.getTree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
    // 总题数
    totalQuestion() {

        let num = 0
        if(this.paperRulesForm.questionConfigList.length>0){
          this.paperRulesForm.questionConfigList.forEach((e,i)=>{
            let questionNum = e.questionNum?e.questionNum:0
            num = num + questionNum
          })
        }

        return num
    },
    // 总分数
    totalScore() {

        let num = 0
        if(this.paperRulesForm.questionConfigList.length>0){
          this.paperRulesForm.questionConfigList.forEach((e,i)=>{
            let questionScore = 0
            if(e.questionNum&&e.questionNum>0){
              questionScore = e.questionScore?e.questionScore:0
              num = num + (questionScore*e.questionNum)
            }

          })
        }
        if(num&&num>0){
          if(this.changePassScore){
            this.paperRulesForm.passScore = (num*0.6).toFixed(2)
          }else{
            this.changePassScore = true
          }

        }


        return num.toFixed(2)
    },


  },
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.questionbankName) {
        params.questionBankName = this.questionbankName;
      }
      this.doFetch(
        {
          url: "/evaluate/question/bank/page",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 点击试卷下载
    handlePaperDownload(row){
      this.$post("/biz/hr_examination/config/getInfo", {questionBankId: row.questionBankId},3000,true,6)
          .then((ret) => {
            if (ret.status == "0") {
              console.log(ret.data)
              if(ret.data.paperName){
                // 批量下载
                this.$post("/biz/hr_examination/config/asyncDownloadPaperBatch", {questionBankId: row.questionBankId},3000,true,6)
                    .then((res) => {
                      if (res.status == "0") {
                        for (let item of res.data) {
                          if (!this.downloadItems.includes(item.taskId)) {
                            this.$store.dispatch("pushDownloadItems", item.taskId);
                          } else {
                            this.$message.warning(
                                "[" + item.fileName + "]已经申请下载,请耐心等待"
                            );
                          }
                        }
                      } else {
                        this.$message.error(res.message);
                      }
                    })
                    .catch((err) => {
                      return;
                    });

              }else{
                this.$message.error('尚未配置试卷规则！');
              }

            } else {
              this.$message.error(ret.message);
            }
          })
          .catch((err) => {
            return;
          });

    },
    // 点击试卷规则
    handlePaperRules(row){
      this.paperRulesdialogVisible = true
      this.$post("/biz/hr_examination/config/getInfo", {questionBankId: row.questionBankId},3000,true,6)
          .then((res) => {
            if (res.status == "0") {
              console.log(res.data)
              this.paperRulesForm = res.data

              this.$set(this.paperRulesForm,'passScore',res.data.passScore?res.data.passScore:undefined)
              if(res.data.passScore){
                this.changePassScore = false
              }
              this.$set(this.paperRulesForm,'timeLimit',res.data.timeLimit?res.data.timeLimit:120)
              this.$set(this.paperRulesForm,'paperNum',res.data.paperNum?res.data.paperNum:1)
              this.$set(this.paperRulesForm,'override',false)
              if(res.data.paperNum){
                this.createStu = true
              }
              let questionConfigList = []
              res.data.questionConfigList.forEach((e,i)=>{
                let item = {
                  maxNum: e.maxNum,
                  questionNum: e.questionNum?e.questionNum:undefined,
                  questionScore: e.questionScore?e.questionScore:undefined,
                  questionType: e.questionType
                }
                questionConfigList.push(item)
              })
              this.$set(this.paperRulesForm,'questionConfigList',questionConfigList)
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 试卷规则提交
    paperRulesSave(){
      // this.paperRulesdialogVisible = false
      if(!this.paperRulesForm.paperName){
        this.$message.error('请填写试卷名称！');
        return
      }

      if(this.paperRulesForm.questionConfigList.length>0){
        try {

          let isAllNull = true// 是否一条题目设置没填
          this.paperRulesForm.questionConfigList.forEach((item, index) => {
            if ((!item.questionNum&&item.questionScore)||(item.questionNum&&!item.questionScore)) {
              throw new Error('请补全或清空第'+ (index+1) + '条题目设置！')
            }
            if(item.questionNum&&item.questionScore){
              isAllNull = false
            }
          })
          if(isAllNull){
            throw new Error('请至少填写一条题目设置！')
          }
        } catch (e) {
          this.$message.error(e.message)
          return
        };
      }
      if(!this.paperRulesForm.passScore){
        this.$message.error('请填写及格分数！');
        return
      }
      if(!this.paperRulesForm.timeLimit){
        this.$message.error('请填写考试时间！');
        return
      }
      if(!this.paperRulesForm.paperNum){
        this.$message.error('请填写试卷数量！');
        return
      }
      console.log(this.paperRulesForm)
      let params = JSON.parse(JSON.stringify(this.paperRulesForm))//简易深拷贝
      params.questionConfigList.forEach((e,i)=>{
        if( e.questionNum == undefined){
          e.questionNum = 0
        }
        if( e.questionScore == undefined){
          e.questionScore = 0
        }
      })
      console.log(params)
      // 如果已经生成过，需要添加确认提示
      if(this.paperRulesForm.override){
        this.$confirm("您勾选了清除原有试卷选项，删除的试卷将不可恢复，确认删除？", "提示", {
          confirmButtonText: "继续生成",
          cancelButtonText: "取消生成",
          type: "warning"
        })
            .then(() => {
              this.paperRulesDoAjax(params)
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消生成"
              });
            });
      }else{
        this.paperRulesDoAjax(params)
      }
    },
    // 试卷规则提交接口
    paperRulesDoAjax(params){
      this.$post("/biz/hr_examination/config/setting", params,3000,true,6)
          .then((res) => {
            if (res.status == "0") {
              this.$message.success('配置成功！');
              this.paperRulesHandleClose();
              this.getData(-1);
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 试卷规则关闭
    paperRulesHandleClose(){
      this.createStu = false
      this.paperRulesForm =  {
        paperName: '',
        questionConfigList: [],
        passScore: undefined,
        timeLimit: 120,
        paperNum: 1,
        override: false
      }
      this.paperRulesdialogVisible = false
    },
    // 点击试卷预览
    handlePapersPreview(row){
      this.$post("/biz/hr_examination/config/getInfo", {questionBankId: row.questionBankId},3000,true,6)
          .then((res) => {
            if (res.status == "0") {
              console.log(res.data)
              if(res.data.paperName){
                this.$refs.PapersPreview.showPopUp(row.questionBankId); // 打开弹窗预览试题
              }else{
                this.$message.error('尚未配置试卷规则！');
              }

            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });

    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {

  },
};
</script>
<style lang="less" scoped>
.paperRules /deep/.el-dialog__body{
  padding-bottom: 0;
}
.paperRules /deep/.el-row{
  margin-bottom: 20px;
}
.paperRules /deep/.el-input__suffix{
  display: flex;
  align-items: center;
}
//.paperRules /deep/.el-input .el-input__count .el-input__count-inner{
//  line-height: 28px;
//}
</style>
